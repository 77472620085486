var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"selectbox",class:{ disabled: _vm.readonly, error: _vm.error },on:{"click":_vm.onClickSelectBox}},[_c('div',{staticClass:"selected"},[(_vm.text)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]):(_vm.selected)?_c('SelectBoxItem',{attrs:{"id":_vm.selected.id,"name":_vm.selected.name,"image":_vm.selected.image || _vm.placeholderImage,"sub-name":_vm.nolaNovelTitle}}):_c('div',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.allowUnselected ? "未選択" : _vm.placeholder))]),(!_vm.readonly)?_c('ChevronDownIcon',{staticClass:"caret",class:{ open: _vm.isOpen },attrs:{"fillColor":"#669cd9"}}):_vm._e()],1),(_vm.isOpen)?_c('div',{staticClass:"dropdown"},[(_vm.allowUnselected)?_c('SelectBoxItem',{key:undefined,staticClass:"item",attrs:{"name":"未選択","image":_vm.placeholderImage},on:{"click":function($event){return _vm.onClickItem(undefined)}}}):_vm._e(),_vm._m(0),_vm._l((_vm.categorized.nolaNovelAssociated),function(option){return [_c('div',{key:option.id,staticClass:"select-box-container",class:{ ['selected-item']: option.id == _vm.selected?.id },on:{"click":function($event){return _vm.onClickItem(option)}}},[_c('ImageView',{staticClass:"image",attrs:{"src":option.image || _vm.placeholderImage}}),_c('div',{staticClass:"name"},[_c('div',{staticClass:"novel-title"},[_vm._v(_vm._s(option.name))]),_c('div',{staticClass:"nola-novel-title",class:{ ['selected-item']: option.id == _vm.selected?.id }},[_vm._v(" Nolaノベル作品名："+_vm._s(_vm.nolaNovelRelatedNovels[option.associatedData?.nolaNovel?.id]?.title || "-")+" ")])]),_c('div',{staticClass:"label",class:{
            ['limited-public']: option.isLimitedPublic,
            ['public']: option.isPublic,
            ['private']: option.associatedData?.nolaNovel?.id && !option.isLimitedPublic && !option.isPublic,
          }})],1)]}),_vm._m(1),_vm._l((_vm.categorized.other),function(option){return [_c('div',{key:option.id,staticClass:"select-box-container disabled",on:{"click":function($event){return _vm.onClickDisabledItem(option)}}},[_c('ImageView',{staticClass:"image",attrs:{"src":option.image || _vm.placeholderImage}}),_c('div',{staticClass:"name"},[_c('div',{staticClass:"novel-title"},[_vm._v(_vm._s(option.name))]),_c('div',{staticClass:"nola-novel-title"},[_vm._v(" Nolaノベル作品名："+_vm._s(_vm.nolaNovelRelatedNovels[option.associatedData?.nolaNovel.id]?.title || "-")+" ")])]),_c('div',{staticClass:"label",class:{
            ['limited-public']: option.isLimitedPublic,
            ['public']: option.isPublic,
            ['private']: option.associatedData?.nolaNovel?.id && !option.isLimitedPublic && !option.isPublic,
          }})],1)]})],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-title"},[_vm._v(" 応募可能な作品 "),_c('aside',[_vm._v("※1件以上のエピソードが含まれたNolaノベル作品のみを表示しています。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-title"},[_vm._v(" 応募条件に一致しない作品 "),_c('aside',[_vm._v("※公開ステータスを変更しエピソードを投稿することで応募可能な作品となります。")])])
}]

export { render, staticRenderFns }