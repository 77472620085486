import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";

import RootTemplate from "@/components/templates/RootTemplate.vue";
import PlainTemplate from "@/components/templates/PlainTemplate.vue";
import NovelTemplate from "@/components/templates/NovelTemplate.vue";
import HeaderTemplate from "@/components/templates/HeaderTemplate.vue";

import { Auth } from "aws-amplify";

// import { openNotifyDialog } from "@/lib/dialog";
import { Plugin } from "@/lib/modified-status";
import { callApi } from "@/lib/api";
import { isTwitterLogin } from "@/lib/twitterAuth";
import { isMaintainance } from "@/lib/isMaintainance";
import isMobile from "ismobilejs";
import { usePageEditStore } from "@/stores/pageEdit";

/* Home */
const Home = () => import(/* webpackChunkName: "group-mypage" */ "./pages/HomePage.vue");
const News = () => import(/* webpackChunkName: "group-mypage" */ "./pages/News.vue");
const NolaNovel = () => import(/* webpackChunkName: "group-mypage" */ "./pages/NolaNovel.vue");
const NolaNolaNovelNotificationNovel = () =>
  import(/* webpackChunkName: "group-mypage" */ "./pages/NolaNovelNotification.vue");
const EventTheme = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventTheme.vue");
const EventContest = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventContest.vue");
const EventContestDetail = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventContestDetail.vue");
const EventContestEntry = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventContestEntry.vue");
const EventAgentEntry = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventAgentEntry.vue");
const EventCompany = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EventCompany.vue");
const EnteredContests = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EnteredContests.vue");
const EntryProfile = () => import(/* webpackChunkName: "group-mypage" */ "./pages/EntryProfile.vue");
const MyPage = () => import(/* webpackChunkName: "group-mypage" */ "./pages/MyPage.vue");
const NovelCreate = () => import(/* webpackChunkName: "group-mypage" */ "./pages/novel/NovelCreatePage.vue");
const Agent = () => import("./pages/Agent.vue");
const AgentIntro = () => import("./pages/AgentIntro.vue");
const AgentDescription = () => import("./pages/AgentDescription.vue");
const AgentNotifications = () => import("./pages/AgentNotifications.vue");

/* Template */
const header = () => import(/* webpackChunkName: "group-mypage" */ "./components/organisms/Header.vue");
const headerOnlyLogo = () => import("./components/organisms/HeaderOnlyLogo.vue");
const side = () => import(/* webpackChunkName: "group-mypage" */ "./components/organisms/SideMenu.vue");
const fixedSide = () => import("./components/organisms/FixedSideMenu.vue");
const nolaNovelSideMenu = () => import("./components/organisms/NolaNovelSideMenu.vue");
const eventSideMenu = () => import("./components/organisms/EventSideMenu.vue");
const agentSideMenu = () => import("./components/organisms/AgentSideMenu.vue");

/* TopPage */
const Top = () => import(/* webpackChunkName: "group-mypage" */ "./pages/TopPage.vue");
const ChangePassword = () => import(/* webpackChunkName: "group-mypage" */ "./pages/ChangePasswordPage.vue");
const ChangeEmail = () => import(/* webpackChunkName: "group-mypage" */ "./pages/ChangeEmailPage.vue");

/* Auth */
const SignUp = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/SignUpPage.vue");
const ResendConfirmCode = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/ResendConfirmCodePage.vue");
const SignIn = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/SignInPage.vue");
const SignInTwitterAccount = () =>
  import(/* webpackChunkName: "group-auth" */ "./pages/auth/SignInTwitterAccountPage.vue");
const SignInConnectedAccount = () =>
  import(/* webpackChunkName: "group-auth" */ "./pages/auth/SignInConnectedAccountPage.vue");
const ResetPassword = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/ResetPasswordPage.vue");
const SchoolAccountResetPassword = () =>
  import(/* webpackChunkName: "group-auth" */ "./pages/auth/SchoolAccountResetPasswordPage.vue");
const ResetPasswordSubmit = () =>
  import(/* webpackChunkName: "group-auth" */ "./pages/auth/ResetPasswordSubmitPage.vue");
const NewPassword = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/NewPasswordPage.vue");
const ConfirmEmail = () => import(/* webpackChunkName: "group-auth" */ "./pages/auth/ConfirmEmailPage.vue");
const AccountDeleted = () => import(/* webpackChunkName: "group-mypage" */ "./pages/statics/AccountDeleted.vue");

/* Novel */
const NovelDetail = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/NovelDetailPage.vue");
const NovelDetailEdit = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/NovelDetailEditPage.vue");

const Characters = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/CharactersPage.vue");
const CharacterCreate = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/CharacterCreatePage.vue");
const CharacterEdit = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/CharacterEditPage.vue");

const WorldViews = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/WorldViewsPage.vue");
const WorldViewCreate = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/WorldViewCreatePage.vue");
const WorldViewEdit = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/WorldViewEditPage.vue");

const Correlations = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/CorrelationsPage.vue");

const Plot = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/PlotPage.vue");
const PlotEdit = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/PlotEditPage.vue");
const PlotChange = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/PlotChangePage.vue");

const Timeline = () => import("./pages/novel/TimelinePage.vue");

const Theme = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/ThemePage.vue");
const ManuscriptEditor = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/ManuscriptEditorPage.vue");
const ManuscriptPreview = () => import(/* webpackChunkName: "group-novel" */ "./pages/novel/ManuscriptPreviewPage.vue");
const MemoInNovel = () => import("./pages/novel/Memo.vue");
const SharedContent = () => import("./pages/novel/SharedContentPage.vue");
const MultiManuscriptEditor = () => import("./pages/novel/MultiManuscriptEditorPage.vue");

const Materials = () => import("./pages/novel/MaterialsPage.vue");
const MaterialTemplates = () => import("./pages/novel/MaterialTemplatesPage.vue");
const MaterialEdit = () => import("./pages/novel/MaterialEditPage.vue");
const MaterialTemplateEdit = () => import("./pages/novel/MaterialTemplateEditPage.vue");
const VerticalExport = () => import(/* webpackChunkName: "group-export" */ "./pages/export/VerticalExportPage.vue");
const HorizontalExport = () => import(/* webpackChunkName: "group-export" */ "./pages/export/HorizontalExportPage.vue");

const Help = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Help.vue");
const Terms = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Terms.vue");
const SchoolAccountTerms = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/statics/SchoolAccountTerms.vue");
const Privacy = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Privacy.vue");
const Membership = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Membership.vue");
const Shared = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Shared.vue");
const Contact = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Contact.vue");

/** Payment */
const SubscriptionAnnounce = () => import("./pages/statics/SubscriptionAnnounce.vue");
const RegisterSubscription = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/payment/RegisterSubscriptionPage.vue");
const ModifySubscription = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/payment/ModifySubscriptionPage.vue");
const SubscriptionCompleted = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/statics/SubscriptionCompleted.vue");
const UnsubscriptionAnnounce = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/statics/UnsubscriptionAnnounce.vue");
const ConfirmInAppPurchase = () =>
  import(/* webpackChunkName: "group-static" */ "./pages/statics/ConfirmInAppPurchase.vue");

/** メモ画面(単体) */
const Memo = () => import("./pages/Memo.vue");

/** 案内ページ */
const Announce = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Announce.vue");

/** データ移行 */
const Migrate = () => import(/* webpackChunkName: "group-static" */ "./pages/migrate/Migrate.vue");

const ErrorNotFoundPage = () => import(/* webpackChunkName: "group-static" */ "./pages/error/NotFoundPage.vue");
const ErrorMaintenancePage = () => import(/* webpackChunkName: "group-static" */ "./pages/error/MaintenancePage.vue");
const SharedLinkAccessDenied = () => import("./pages/error/SharedLinkAccessDeniedPage.vue");
const AnnualReport2023 = () => import(/* webpackChunkName: "group-static" */ "./pages/error/AnnualReport2023.vue");

/** フィルムアート社 特設ページ */
const Knowhow = () => import(/* webpackChunkName: "group-static" */ "./pages/statics/Knowhow.vue");

Vue.use(Router);

/** 認証タイプ */
const enum AuthType {
  /** 認証済ユーザーのみ表示 */
  REQUIRE_AUTH = "require_auth",
  /** 未認証ユーザーのみ表示 */
  REQUIRE_NOT_AUTH = "require_not_auth",
}

interface PageParam {
  path: string;
  redirect?: string;
  name?: string;
  component?: any;
  template?: any;
  headerTemplate?: any;
  sideTemplate?: any;
  title?: string;
  authType?: string | null;
}

function RootPage({
  path,
  name,
  component,
  template,
  title,
  redirect,
  headerTemplate,
  sideTemplate,
  authType = AuthType.REQUIRE_AUTH,
}: PageParam) {
  return {
    path,
    redirect,
    name,
    components: {
      template: template || RootTemplate,
      default: component,
      header: headerTemplate || header,
      side: sideTemplate || side,
    },
    props: { template: true, default: true, side: true },
    meta: {
      authType,
      title,
    },
  };
}

function NovelPage({
  path,
  name,
  component,
  template,
  title,
  headerTemplate,
  sideTemplate,
  authType = AuthType.REQUIRE_AUTH,
}: PageParam) {
  return {
    path,
    name,
    components: {
      template: template || NovelTemplate,
      default: component,
      header: isMobileDevice() ? header : headerTemplate,
      side: sideTemplate || side,
    },
    props: { template: true, default: true, side: true },
    meta: {
      authType,
      title,
    },
    beforeEnter: async (to: Route, _: Route, next: NavigationGuardNext) => {
      if (Object.prototype.hasOwnProperty.call(to.params, "novelId")) {
        const result = await existsNovel(to.params.novelId);
        if (result) {
          next();
        } else {
          next({ name: "home" });
        }
      } else {
        next();
      }
    },
  };
}

function PlainPage({ path, name, component, title, headerTemplate }: PageParam, authType: AuthType | null = null) {
  return {
    path,
    name,
    components: {
      template: PlainTemplate,
      default: component,
      header: headerTemplate,
    },
    props: { default: true },
    meta: {
      authType,
      title,
    },
  };
}

const router = new Router({
  mode: "history",
  base: "",
  routes: [
    {
      path: "/index.html",
      redirect: "/home",
    },
    PlainPage(
      {
        path: "/",
        name: "top",
        component: Top,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/lp",
        name: "top-lp",
        component: Top,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    RootPage({
      template: HeaderTemplate,
      path: "/home",
      name: "home",
      component: Home,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/news",
      name: "news",
      component: News,
    }),
    RootPage({
      path: "/event",
      redirect: "/event/theme",
    }),
    RootPage({
      path: "/event/theme",
      name: "eventTheme",
      component: EventTheme,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/event/contest",
      name: "eventContest",
      component: EventContest,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/event/contest/:id",
      name: "eventContestDetail",
      component: EventContestDetail,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/event/contest/:id/entry",
      redirect: "/event/contest/:id",
    }),
    RootPage({
      path: "/event/contest/:id/entry/:contestId",
      name: "eventContestEntry",
      component: EventContestEntry,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/event/contest/:id/entry/agent/:campaignId",
      name: "eventAgentEntry",
      component: EventAgentEntry,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/event/company",
      name: "eventCompany",
      component: EventCompany,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/entries",
      name: "entries",
      component: EnteredContests,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/entryProfile",
      name: "entryProfile",
      component: EntryProfile,
      sideTemplate: eventSideMenu,
    }),
    RootPage({
      path: "/nolanovel",
      name: "nolaNovel",
      component: NolaNovel,
      sideTemplate: nolaNovelSideMenu,
    }),
    RootPage({
      path: "/nolanovel/notification",
      name: "nolaNovelNotification",
      component: NolaNolaNovelNotificationNovel,
      sideTemplate: nolaNovelSideMenu,
    }),
    RootPage({
      title: "マイページ",
      template: HeaderTemplate,
      path: "/mypage",
      name: "myPage",
      component: MyPage,
    }),
    RootPage({
      title: "パスワード変更",
      template: HeaderTemplate,
      path: "/changepassword",
      name: "changePassword",
      component: ChangePassword,
    }),
    RootPage({
      title: "メールアドレス変更",
      template: HeaderTemplate,
      path: "/changeemail",
      name: "changeEmail",
      component: ChangeEmail,
    }),
    RootPage({
      title: "新規作品作成",
      template: HeaderTemplate,
      path: "/novels/create",
      name: "novelCreate",
      component: NovelCreate,
    }),
    RootPage({
      title: "エージェント",
      path: "/agent",
      name: "agent",
      component: Agent,
      sideTemplate: agentSideMenu,
    }),
    RootPage({
      title: "エージェントについて",
      path: "/agent/description",
      name: "agentDescription",
      component: AgentDescription,
      sideTemplate: agentSideMenu,
    }),
    RootPage({
      title: "エージェント利用開始にあたって",
      path: "/agent/intro",
      name: "agentIntro",
      component: AgentIntro,
      template: HeaderTemplate,
    }),
    RootPage({
      title: "エージェント受信箱",
      path: "/agent/notifications",
      name: "agentNotifications",
      component: AgentNotifications,
      sideTemplate: agentSideMenu,
    }),
    NovelPage({
      title: "作品情報",
      name: "detail",
      path: "/:novelId/detail",
      component: NovelDetail,
    }),
    NovelPage({
      title: "作品情報編集",
      name: "detailEdit",
      path: "/:novelId/detail/edit",
      component: NovelDetailEdit,
    }),
    NovelPage({
      title: "テーマ",
      name: "theme",
      path: "/:novelId/theme",
      component: Theme,
    }),
    NovelPage({
      title: "登場人物新規作成",
      name: "characterCreate",
      path: "/:novelId/characters/create",
      component: CharacterCreate,
    }),
    NovelPage({
      title: "登場人物編集",
      name: "characterEdit",
      path: "/:novelId/characters/:characterId?/edit",
      component: CharacterEdit,
    }),
    NovelPage({
      title: "登場人物",
      name: "characters",
      path: "/:novelId/characters/:characterId?",
      component: Characters,
    }),
    NovelPage({
      title: "世界観新規作成",
      name: "worldViewCreate",
      path: "/:novelId/worldviews/create",
      component: WorldViewCreate,
    }),
    NovelPage({
      title: "世界観編集",
      name: "worldViewEdit",
      path: "/:novelId/worldviews/:worldViewId/edit",
      component: WorldViewEdit,
    }),
    NovelPage({
      title: "世界観",
      name: "worldViews",
      path: "/:novelId/worldviews/:worldViewId?",
      component: WorldViews,
    }),
    NovelPage({
      title: "相関関係",
      name: "correlations",
      path: "/:novelId/correlations/:correlationGroupId?/:correlationId?",
      component: Correlations,
    }),
    NovelPage({
      title: "資料",
      name: "materials",
      path: "/:novelId/materials/:materialAttributeId?/:materialId?",
      component: Materials,
    }),
    NovelPage({
      title: "資料編集",
      name: "materialEdit",
      path: "/:novelId/materials/:materialAttributeId/:materialId/edit",
      component: MaterialEdit,
    }),
    NovelPage({
      title: "資料テンプレート",
      name: "templates",
      path: "/:novelId/templates/:templateId?",
      component: MaterialTemplates,
    }),
    NovelPage({
      title: "資料テンプレート編集",
      name: "templateEdit",
      path: "/:novelId/templates/:templateId/edit",
      component: MaterialTemplateEdit,
    }),
    NovelPage({
      title: "執筆",
      name: "editor",
      path: "/:novelId/editor/:manuscriptKey?",
      component: ManuscriptEditor,
    }),
    PlainPage(
      {
        title: "マルチ執筆モード",
        name: "multiEditor",
        path: "/:novelId/multiEditor/:manuscriptKey?",
        component: MultiManuscriptEditor,
      },
      AuthType.REQUIRE_AUTH
    ),
    PlainPage(
      {
        title: "作品プレビュー",
        name: "preview",
        path: "/:novelId/preview",
        component: ManuscriptPreview,
      },
      AuthType.REQUIRE_AUTH
    ),
    NovelPage({
      title: "プロット",
      name: "plot",
      path: "/:novelId/plot/:plotId?",
      component: Plot,
    }),
    NovelPage({
      title: "プロット編集",
      name: "plotEdit",
      path: "/:novelId/plot/:plotId?/edit",
      component: PlotEdit,
    }),
    NovelPage({
      title: "プロットモード変更",
      name: "plotChange",
      path: "/:novelId/plot/:plotId?/change",
      component: PlotChange,
    }),
    NovelPage({
      title: "時系列",
      name: "timeline",
      path: "/:novelId/timeline/:timelineType?/:timelineId?",
      component: Timeline,
    }),
    PlainPage(
      {
        path: "/migrate",
        name: "migrate",
        component: Migrate,
      },
      AuthType.REQUIRE_AUTH
    ),
    PlainPage(
      {
        path: "/auth/signup",
        name: "signUp",
        component: SignUp,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/signin",
        name: "signIn",
        component: SignIn,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/signinTwitterAccount",
        name: "signInTwitterAccount",
        component: SignInTwitterAccount,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/signinConnectedAccount",
        name: "signInConnectedAccount",
        component: SignInConnectedAccount,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/resendconfirmcode",
        name: "resendConfirmCode",
        component: ResendConfirmCode,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/resetpassword",
        name: "resetPassword",
        component: ResetPassword,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/schoolaccount-resetpassword",
        name: "schoolAccountResetPassword",
        component: SchoolAccountResetPassword,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/resetpasswordsubmit/:username",
        name: "resetPasswordSubmit",
        component: ResetPasswordSubmit,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage(
      {
        path: "/auth/newpassword",
        name: "newPassword",
        component: NewPassword,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage({
      path: "/auth/confirmemail/:id",
      name: "confirmEmail",
      component: ConfirmEmail,
    }),
    PlainPage(
      {
        title: "アカウント削除完了",
        path: "/accountDeleted",
        name: "accountDeleted",
        component: AccountDeleted,
        headerTemplate: headerOnlyLogo,
      },
      AuthType.REQUIRE_NOT_AUTH
    ),
    PlainPage({
      path: "/help",
      name: "help",
      component: Help,
    }),
    PlainPage({
      path: "/privacy",
      name: "privacy",
      component: Privacy,
    }),
    PlainPage({
      path: "/terms",
      name: "terms",
      component: Terms,
    }),
    PlainPage({
      path: "/schoolAccountTerms",
      name: "schoolAccountTerms",
      component: SchoolAccountTerms,
    }),
    PlainPage({
      path: "/membership",
      name: "membership",
      component: Membership,
    }),
    PlainPage({
      path: "/shared",
      name: "shared",
      component: Shared,
    }),
    PlainPage({
      path: "/maintenance",
      name: "maintenance",
      component: ErrorMaintenancePage,
    }),
    PlainPage({
      path: "/announce",
      name: "announce",
      component: Announce,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/subscription/register/:plan",
      name: "registerSubscription",
      component: RegisterSubscription,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/subscription/modify",
      name: "subscriptionModify",
      component: ModifySubscription,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/subscription/modify/payment",
      name: "modifyPayment",
      component: RegisterSubscription,
    }),
    PlainPage(
      {
        path: "/subscription",
        name: "subscriptionAnnounce",
        component: SubscriptionAnnounce,
      },
      AuthType.REQUIRE_AUTH
    ),
    RootPage({
      template: HeaderTemplate,
      path: "/subscription/completed",
      name: "subscriptionCompleted",
      component: SubscriptionCompleted,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/unsubscription",
      name: "unsubscriptionAnnounce",
      component: UnsubscriptionAnnounce,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/confirmInAppPurchase",
      name: "confirmInAppPurchase",
      component: ConfirmInAppPurchase,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/unsubscription/completed",
      name: "unsubscriptionCompleted",
      component: SubscriptionCompleted,
    }),
    RootPage({
      template: HeaderTemplate,
      path: "/contact",
      name: "contact",
      component: Contact,
      authType: null,
    }),
    NovelPage({
      path: "/memo/:memoId?",
      name: "memo",
      component: Memo,
      sideTemplate: fixedSide,
    }),
    NovelPage({
      path: "/:novelId/memo/:memoId?",
      name: "memoInNovel",
      component: MemoInNovel,
    }),
    PlainPage(
      {
        path: "/share/:sharedLinkId/:novelId/:contentKey?",
        name: "sharedContent",
        component: SharedContent,
        headerTemplate: headerOnlyLogo,
      },
      AuthType.REQUIRE_AUTH
    ),
    PlainPage({
      path: "/share/accessdenied",
      name: "sharedLinkAccessDenied",
      component: SharedLinkAccessDenied,
      headerTemplate: headerOnlyLogo,
    }),
    PlainPage({
      title: "縦書きPDF出力",
      name: "verticalExport",
      path: "/verticalExport",
      component: VerticalExport,
    }),
    PlainPage({
      title: "横書きPDF出力",
      name: "horizontalExport",
      path: "/horizontalExport",
      component: HorizontalExport,
    }),
    PlainPage({
      title: "創作の学び舎｜執筆活動のヒントが学べる本屋さん",
      path: "/knowhow",
      name: "knowhow",
      component: Knowhow,
    }),
    PlainPage({
      title: "執筆ダイアリー2023",
      path: "/annual-report-2023",
      name: "annualReport2023",
      component: AnnualReport2023,
    }),
    {
      path: "*",
      name: "404",
      components: {
        template: PlainTemplate,
        default: ErrorNotFoundPage,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return { x: 0, y: 0 };
  },
});

Vue.use(new Plugin());
// router.beforeEach(async (to, from, next) => {
//   // 保存
//   if (isModified()) {
//     const yes = await openNotifyDialog({ content: '保存されていない変更があるけど戻る？' });
//     if (!yes) {
//       return;
//     }
//     setModified(false);
//   }
//   next();
// });

function postMessage(type: unknown, message: any = null) {
  if (window.parent !== window.top) {
    window.parent.postMessage({ nola: true, type, message }, "*");
  }
}

router.beforeEach((to, from, next) => {
  let { title } = to.meta;
  title = title ? `Nola ${title}` : process.env.VUE_APP_TITLE;
  document.title = title;
  next();
});

router.beforeEach(async (to, from, next) => {
  // メンテナンス時にメンテナンス画面にリダイレクト
  const _isMaintenance = await isMaintainance();
  if (_isMaintenance && to.name !== "maintenance") {
    next({ name: "maintenance" });
    return;
  }
  if (!_isMaintenance && to.name === "maintenance") {
    next({ name: "top" });
    return;
  }

  postMessage("history", to.fullPath);

  const user = (await Auth.currentCredentials().catch((err) => {
    if (err.code === "UserNotFoundException" || err.code === "NotAuthorizedException") {
      return;
    }
    if (err !== "No current user") {
      throw err;
    }
  })) as any;

  const isAuthenticated = user.authenticated;
  const isLogin = user && !user.code && (isTwitterLogin() || isAuthenticated);

  if (isLogin) {
    try {
      const sub = user.signInUserSession ? user.signInUserSession.idToken.payload.sub : user.data.IdentityId;

      (Vue as any).$ga.set("userId", sub);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  /** 未ログインユーザーが共有リンクにアクセスした時、新規登録案内へリダイレクト */
  if (to.name === "sharedContent") {
    if (!isLogin) {
      next({
        name: "sharedLinkAccessDenied",
        query: { reason: "notlogin" },
      });
      return;
    }
  }

  if (to.matched.some((record) => record.meta.authType === AuthType.REQUIRE_AUTH)) {
    if (!isLogin) {
      next({
        name: "top",
        query: { redirect: to.fullPath },
      });
      return;
    }
  } else if (to.matched.some((record) => record.meta.authType === AuthType.REQUIRE_NOT_AUTH)) {
    if (isLogin) {
      next({
        name: "home",
      });
      return;
    }
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  // 編集を保存していない状態で画面遷移をしようとした際に確認ダイアログを表示する
  const pageEditStore = usePageEditStore();
  if (await pageEditStore.confirmUnsavedChanges()) {
    next(); // ユーザーが遷移を許可した場合
  } else {
    next(false); // ユーザーが遷移をキャンセルした場合
  }
});

async function existsNovel(novelId: string) {
  const { novel } = await callApi(
    `query Novel ($novelId: ID!) {
        novel ( novelId: $novelId ) {
          novelId
        }
      }`,
    {
      novelId,
    }
  );

  if (novel) {
    return true;
  }
  return false;
}

function isMobileDevice() {
  return isMobile().phone || (isMobile().tablet && window.innerWidth < 1024);
}

export default router;
