import { render, staticRenderFns } from "./AnnualReport2023.vue?vue&type=template&id=25c162fa&scoped=true"
import script from "./AnnualReport2023.vue?vue&type=script&lang=ts"
export * from "./AnnualReport2023.vue?vue&type=script&lang=ts"
import style0 from "./AnnualReport2023.vue?vue&type=style&index=0&id=25c162fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c162fa",
  null
  
)

export default component.exports