<template>
  <div class="h-full w-full flex flex-column">
    <slot name="header" />
    <transition name="fade">
      <div class="grid" :class="{ 'grid-expanded': isExpandedLayout }">
        <slot name="side" />
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    isExpandedLayout() {
      return this.$route.path === "/nolanovel" || this.$route.path === "/agent";
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: calc(100vh - #{$header-height});
}

.grid-expanded {
  grid-template-columns: 360px 1fr; /* 特定パス用のカラム幅 */
}
</style>
