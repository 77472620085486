import { render, staticRenderFns } from "./AgentDescription.vue?vue&type=template&id=042c0400"
import script from "./AgentDescription.vue?vue&type=script&lang=ts"
export * from "./AgentDescription.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports