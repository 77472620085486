import { User, Novel, NovelFromNolaNovel } from "@/lib/models";
import { isBilling } from "@/lib/utils";
import store from "@/store";

export const evaluateBanner = (banner: any, novels: Novel[], novelsFromNolaNovel: NovelFromNolaNovel[], user: User) => {
  const { operator, conditions } = banner;

  // 表示条件
  const matchConditions = conditions.map((condition: any) => {
    const { fieldId, value, min, max } = condition;
    switch (fieldId) {
      // 制作状況
      case "workStatus": {
        const items = novels.filter((novel: Novel) => {
          let { workStatus } = novel;
          if (!workStatus) workStatus = "inProgress";
          return value.includes(workStatus);
        });
        if (items.length === 0) return false;
        if ((min && items.length < min) || (max && items.length > max)) return false;
        return true;
      }

      // ジャンル
      case "genre": {
        const items = novels.filter((novel: Novel) => {
          const { genre } = novel;
          return genre && value.includes(genre);
        });
        if (items.length === 0) return false;
        if ((min && items.length < min) || (max && items.length > max)) return false;
        return true;
      }

      // カテゴリ
      case "category": {
        const items = novels.filter((novel: Novel) => {
          const { category } = novel;
          return category && category.some((x) => value.includes(x));
        });
        if (items.length === 0) return false;
        if ((min && items.length < min) || (max && items.length > max)) return false;
        return true;
      }

      // ジャンル（Nolaノベル）
      case "nolaNovelGenre": {
        const items = novelsFromNolaNovel.filter((novel: NovelFromNolaNovel) => {
          const { genre } = novel;
          return genre && value.includes(genre);
        });
        if (items.length === 0) return false;
        if ((min && items.length < min) || (max && items.length > max)) return false;
        return true;
      }

      // サブジャンル（Nolaノベル）
      case "nolaNovelSubGenre": {
        const items = novelsFromNolaNovel.filter((novel: NovelFromNolaNovel) => {
          const { subGenre } = novel;
          return subGenre && value.includes(subGenre);
        });
        if (items.length === 0) return false;
        if ((min && items.length < min) || (max && items.length > max)) return false;
        return true;
      }

      // エージェント利用状況
      case "agentStatus": {
        const isAgentStarted = !!user.agentStartedAt;
        const isMatchValue = (value.includes("on") && isAgentStarted) || (value.includes("off") && !isAgentStarted);
        if (isMatchValue) {
          // エージェントを有効化していない場合、min/maxは参照しないため、早期リターン
          if (!isAgentStarted) return true;

          // エージェント連携している作品を取得
          const agentLinkedNovels = novels.filter((novel: Novel) => novel.agentStatus === 1);
          if ((min && agentLinkedNovels.length < min) || (max && agentLinkedNovels.length > max)) return false;
          return true;
        }
        return false;
      }

      default: {
        return false;
      }
    }
  });

  // 条件に合致しない場合はfalseを返す
  if (operator[0] === "AND" && !matchConditions.every((x: any) => x)) return false;
  if (operator[0] === "OR" && !matchConditions.some((x: any) => x)) return false;

  // すべての条件を満たした場合はtrueを返す
  return true;
};

export const validateBasicConditions = async (banner: any, currentPage: string | undefined) => {
  // 表示ページの判定
  switch (banner.page?.[0]) {
    case undefined:
      // pageが設定されていない場合はスキップ
      break;
    case "home":
      // pageが "home" の場合は処理を続行
      if (currentPage !== "/home") return false;
      break;
    case "nolanovel":
      // pageが "nolanovel" の場合は処理を続行
      if (currentPage !== "/nolanovel") return false;
      break;
    case "agent":
      // pageが "agent" の場合は処理を続行
      if (currentPage !== "/agent") return false;
      break;
    default:
      // pageが想定外の値の場合はfalseを返す
      return false;
  }

  // 配信期間の判定
  const isWithinPeriodResult = isWithinPeriod(banner.startedAt, banner.endedAt);
  if (isWithinPeriodResult === false) return false;

  // プランの判定
  const isPremium = await isBilling(store);
  switch (banner.subscriptionStatus[0]) {
    // subscriptionStatusが設定されていない場合はスキップ
    default:
    case "all":
      break;
    case "free":
      if (isPremium) return false;
      break;
    case "premium":
      if (!isPremium) return false;
      break;
  }

  return true;
};

const isWithinPeriod = (startedAt?: string, endedAt?: string) => {
  const now = new Date();

  // 配信期間が設定されていない場合は null を返して、条件判定をスキップ
  if (startedAt === undefined && endedAt === undefined) return null;

  const isStarted = startedAt ? new Date(startedAt).getTime() <= now.getTime() : true;
  const isNotEnded = endedAt ? new Date(endedAt).getTime() >= now.getTime() : true;

  // 配信期間内である場合は true、それ以外の場合は false を返す
  return isStarted && isNotEnded;
};
